@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');

body {
  font-family: 'Cairo', sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
}

/* .layout {
  background-attachment: fixed;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(assets/background.jpg);
  background-size: cover;
  background-repeat: no-repeat;
} */
